import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Navbar,
  Nav,
  Card,
  Form,
  Button,
  Table,
  Row,
  Col,
  CardBody,
  Image,
} from "react-bootstrap";
import { BrowserRouter as Router, Link } from "react-router-dom";
import axios from "../utils/axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
//import { removeUserSession } from "../utils/Common";
import Header from "../components/Header";
import Footer from "../components/Footer";
import moment from "moment";
import { utils, writeFileXLSX } from "xlsx";
import "../styles/styles.css"; // Adjust the path if necessary
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";

function Meal() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //const [isSaving, setIsSaving] = useState(false);
  const [searching, setSearching] = useState(false);
  const [checkRangeDate, setCheckRangeDate] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);

  const [data, setData] = useState([]);

  const [filePO, setFilePO] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const getThisWeeksDates = () => {
    const now = new Date();
    const start = new Date(now.setDate(now.getDate() - now.getDay() + 1)); // Start of the week (Monday)
    const end = new Date(now.setDate(start.getDate() + 6)); // End of the week (Sunday)

    const formatDate = (date) => date.toISOString().split("T")[0];

    return {
      startDate: formatDate(start),
      endDate: formatDate(end),
    };
  };

  const getListMenu = async (startDate, endDate) => {
    try {
      console.log("start date: ", startDate);
      console.log("end date: ", endDate);
      setSearching(true);
      setIsLoading(true);
      const response = await axios.get(`api/menu/${startDate}/${endDate}`);
      console.log("data is fetched!: ", response.data.data);
      setData(response.data.data);
      setDataFetched(true);
    } catch (err) {
      console.log(err);
    } finally {
      setSearching(false);
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    setCheckRangeDate(true);
    setFormSubmitted(true);
    setIsLoading(true);
    if (startDate > endDate) {
      // If start date is greater than end date
      setCheckRangeDate(true);
      console.log("check range date : ", checkRangeDate);
    } else {
      setCheckRangeDate(false);
      //console.log("check range date : ", checkRangeDate);
    }
    console.log("button is pressed: ");
    e.preventDefault();
    generateDateRange(startDate, endDate);
    getListMenu(startDate, endDate).then(() => {
      setFormSubmitted(true);
      setDataFetched(true);
      setIsLoading(false);
    });

    console.log("date range:", dateRange);
  };

  const generateDateRange = (start, end) => {
    let startDate = moment(start, "YYYY-MM-DD");
    let endDate = moment(end, "YYYY-MM-DD");
    let range = [];

    while (startDate <= endDate) {
      range.push(startDate.format("YYYY-MM-DD"));
      startDate = startDate.add(1, "days");
    }

    setDateRange(range);
  };

  const handleNameChange = (e, id, forDate, menuCategoryId) => {
    const { value } = e.target;

    // Logging the input values
    //console.log("handleNameChange called with:");
    //console.log("value: ", value);
    // console.log("id: ", id);
    //console.log("forDate: ", forDate);
    // console.log("menuCategoryId: ", menuCategoryId);

    const newData = [...data];
    const newUpdatedData = [...updatedData];

    console.log("Initial newData from name: ", newData);
    console.log("Initial newUpdatedData from name: ", newUpdatedData);

    if (id) {
      // Update existing data
      const existingIndex = newData.findIndex((item) => item.id_food === id);
      if (existingIndex !== -1) {
        newData[existingIndex].nm_food = value;
        //console.log("Updating newData at index: ", existingIndex);
        setData(newData);
      }
    } else {
      // Update or add to updatedData
      const existingUpdatedIndex = newUpdatedData.findIndex(
        (item) =>
          item.for_date === forDate && item.menu_category_id === menuCategoryId
      );

      if (existingUpdatedIndex !== -1) {
        newUpdatedData[existingUpdatedIndex].nm_food = value;
        //console.log("Updating newUpdatedData at index: ", existingUpdatedIndex);
      } else {
        newUpdatedData.push({
          for_date: forDate,
          nm_food: value,
          menu_category_id: menuCategoryId,
        });
        //console.log("Adding new item to newUpdatedData");
      }
      setUpdatedData(newUpdatedData);
    }

    //console.log("Updated newData: ", newData);
    //console.log("Updated newUpdatedData: ", newUpdatedData);
  };

  const renderFoodItems = (date, data) => {
    console.log("data", data);
    console.log("date", date);
    const filteredItems = data.filter((item) => item.for_date === date);
    console.log("filteredItems: ", filteredItems);

    const itemsByCategory = [1, 2, 3, 4].map((categoryId) => {
      const item = filteredItems.find(
        (item) => parseInt(item.menu_category_id, 10) === categoryId
      );
      return (
        item || { nm_food: "", id_food: null, menu_category_id: categoryId }
      );
    });

    console.log("itemsByCategory: ", itemsByCategory);

    return itemsByCategory.map((food, index) => (
      <Col key={index} className="mt-3">
        <Form.Group className="mb-3">
          {food.nm_food !== "" ? (
            <Form.Control
              type="text"
              value={food.nm_food}
              onChange={(e) =>
                handleNameChange(
                  e,
                  food.id_food || null,
                  date,
                  food.menu_category_id
                )
              }
              style={{
                fontSize: "14px",
                textAlign: "center",
                padding: 4,
              }}
              placeholder="Enter name"
            />
          ) : (
            <Form.Control
              type="text"
              onChange={(e) =>
                handleNameChange(
                  e,
                  food.id_food || null,
                  date,
                  food.menu_category_id
                )
              }
              style={{
                fontSize: "14px",
                textAlign: "center",
                padding: 4,
              }}
              placeholder="Enter name"
            />
          )}
        </Form.Group>
      </Col>
    ));
  };

  const handleImageUpload = (date, index, event, id) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const newData = [...data];
      const newUpdatedData = [...updatedData];

      console.log("Initial newData: ", newData);
      console.log("Initial newUpdatedData: ", newUpdatedData);

      // Check if there's existing data in newData using id if provided, otherwise fallback to date and index + 1
      const existingIndex = id
        ? newData.findIndex((item) => item.id_food === id)
        : newData.findIndex(
            (item) =>
              item.for_date === date && item.menu_category_id === index + 1
          );

      console.log("existingIndex:", existingIndex);

      if (existingIndex !== -1) {
        console.log("existingIndex !== -1");
        // Update existing image in newData
        newData[existingIndex].food_img = reader.result;
        setData(newData);
      } else {
        console.log("else existingIndex !== -1");
        // Check if there's existing data in newUpdatedData
        const existingUpdatedIndex = newUpdatedData.findIndex(
          (item) =>
            item.for_date === date && item.menu_category_id === index + 1
        );

        if (existingUpdatedIndex !== -1) {
          console.log("existingUpdatedIndex !== -1");
          // Update existing data in newUpdatedData
          newUpdatedData[existingUpdatedIndex].food_img = reader.result;
        } else {
          console.log("else existingUpdatedIndex === -1");
          // If no existing data, push new data to newUpdatedData
          newUpdatedData.push({
            for_date: date,
            nm_food: "", // Add default values as needed
            menu_category_id: index + 1, // Adjust index to match your data structure
            food_img: reader.result, // Set the image URL directly
          });
        }
        setUpdatedData(newUpdatedData);
      }

      console.log("Updated newData after image upload: ", newData);
      console.log(
        "Updated newUpdatedData after image upload: ",
        newUpdatedData
      );
    };

    if (file) {
      reader.readAsDataURL(file); // Read file as data URL
    }
  };

  const renderFoodImages = (date, data) => {
    const filteredItems = data.filter((item) => item.for_date === date);

    const itemsByCategory = [1, 2, 3, 4].map((categoryId) => {
      const item = filteredItems.find(
        (item) => parseInt(item.menu_category_id, 10) === categoryId
      );
      return (
        item || { nm_food: "", id_food: null, menu_category_id: categoryId }
      );
    });

    return itemsByCategory.map((food, index) => (
      <Col key={index} className="mt-3">
        <Form.Group className="mb-3">
          {/* Image upload input */}
          <Form.Control
            type="file"
            onChange={(e) => handleImageUpload(date, index, e, food.id_food)}
            label="Browse"
          />
          {/* Display existing image if available in newData */}
          {food.food_img && typeof food.food_img === "string" && (
            <img
              src={food.food_img}
              style={{
                width: "100%",
                height: "210px", // Adjust based on your design
                objectFit: "cover", // Ensure the image fills the specified dimensions
                marginTop: "10px",
              }}
              alt={`Image ${food.nm_food}`}
            />
          )}
          {/* Display uploaded image from newUpdatedData if available */}
          {!food.food_img &&
            updatedData.some(
              (item) =>
                item.for_date === date && item.menu_category_id === index + 1
            ) && (
              <img
                src={
                  updatedData.find(
                    (item) =>
                      item.for_date === date &&
                      item.menu_category_id === index + 1
                  ).food_img
                }
                style={{
                  width: "100%",
                  height: "210px", // Adjust based on your design
                  objectFit: "cover", // Ensure the image fills the specified dimensions
                  marginTop: "10px",
                }}
                alt={`Upload Image ${index + 1}`}
              />
            )}
        </Form.Group>
      </Col>
    ));
  };

  const handleSave = async () => {
    // Example API call to save foodNames state
    setIsLoading(true);
    try {
      console.log("save is pressed. updateddata: ", updatedData);
      console.log("save is pressed. data: ", data);

      const response = await axios.post(`api/menu/update`, {
        oldData: data,
        updated: updatedData,
      });
      console.log("API response: ", response.data);
      Swal.fire({
        icon: "success",
        title: "Data Saved Successfully!",
        showConfirmButton: true,
      });
    } catch (error) {
      // Handle any errors
      console.error("Error saving data: ", error);
      Swal.fire({
        icon: "error",
        title: "Failed to save changes!",
        text: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   const { startDate, endDate } = getThisWeeksDates();
  //   setStartDate(startDate);
  //   setEndDate(endDate);
  //   getListMenu(startDate, endDate);
  // }, []);

  // const uploadPOFileNew = (e) => {
  //   let file = e.target.files[0];

  //   const formData = new FormData();
  //   formData.append("file", file);

  //   axios
  //     .post("/api/update/menu", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     .then((response) => {
  //       console.log("upload file succesfull", response.data.url);
  //       setFilePO(response.data.url);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const uploadPOFileNew = (e) => {
    setFilePO(e.target.files[0]);
  };

  const uploadImageNew = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!filePO) {
      Swal.fire({
        icon: "info",
        text: "Please select a file to upload",
      });
      return;
    }

    if (!selectedImage) {
      Swal.fire({
        icon: "info",
        text: "Please select a file to upload",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", filePO);
    formData.append("IMGUpload", selectedImage);

    try {
      const response = await axios.post("/api/menu/update", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: "File uploaded successfully",
        });
      } else {
        Swal.fire({
          icon: "error",
          text:
            response.data.error ||
            "An error occurred while uploading the file.",
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = Object.values(error.response.data.errors)
          .flat()
          .join(", ");
        Swal.fire({
          icon: "error",
          text: errorMessage,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "An error occurred while saving data.",
        });
      }
    }
  };

  return (
    <div>
      <Header />
      <Container className="d-flex flex-column justify-content-center align-items-center p-0 mb-2">
        <Card
          style={{ width: "330px", backgroundColor: "#f8f8f8", border: "none" }}
        >
          <h4
            style={{
              fontWeight: "bold",
              fontSize: 30,
              marginTop: 4,
              marginBottom: 5,
            }}
          >
            Meals Menu
          </h4>
          <p style={{ fontSize: 12, marginBottom: 10 }}>
            Set date range to insert/edit menu
          </p>
        </Card>

        <Container
          className="d-flex justify-content-center align-items-center p-0"
          style={{ height: "auto" }}
        >
          <Form className="d-flex flex-row" onSubmit={handleSearch}>
            <Form.Group className="mb-3 me-2">
              <Form.Label style={{ fontSize: "11px" }}>From</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
                style={{ fontSize: "11px" }}
              />
            </Form.Group>

            <Form.Group className="mb-3 me-2">
              <Form.Label style={{ fontSize: "11px" }}>Until</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
                style={{ fontSize: "11px" }}
              />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className="align-self-end mb-3 me-2"
              style={{ fontSize: "11px" }}
            >
              Search
            </Button>
          </Form>
        </Container>
        <div className="col-md-3 mt-2">
          <label htmlFor="filePO" className="form-label">
            *File PO
          </label>
          <input
            type="file"
            className="form-control form-control-sm"
            id="filePO"
            accept="application/pdf"
            onChange={uploadPOFileNew}
          />

          <label htmlFor="itemImage" className="form-label">
            Item Image
          </label>
          <input
            type="file"
            className="form-control form-control-sm"
            onChange={uploadImageNew}
            id="itemImage"
          ></input>

          <button
            className="btn btn-warning ps-3 pe-3 mt-2"
            style={{ fontSize: "16px", fontWeight: "bold" }}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </Container>

      <Container style={{ paddingBottom: 90 }}>
        {!checkRangeDate ? (
          formSubmitted ? (
            dataFetched && !isLoading ? (
              dateRange.length > 0 ? (
                <>
                  <div
                    className="d-flex flex-row justify-content-end mb-2"
                    style={{ width: "100%" }}
                  >
                    <Button
                      variant="warning"
                      onClick={handleSave}
                      className="ps-3 pe-3"
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    >
                      Save
                    </Button>
                  </div>

                  {dateRange.map((date, index) => (
                    <Card className="p-2 ps-3 pe-3 mb-5" key={index}>
                      <Container className="d-flex flex-row justify-content-between align-items-center p-0">
                        <Card.Text style={{ fontWeight: "700" }}>
                          Menu for : {moment(date).format("dddd, MMMM D, YYYY")}
                        </Card.Text>
                      </Container>

                      <Row className="mb-1">
                        <Col
                          className="fs-6 text-center mt-2 mb-2 border border-grey"
                          style={{
                            fontWeight: "700",
                            marginRight: 12,
                            marginLeft: 12,
                            borderRadius: 6,
                            paddingTop: 4,
                            paddingBottom: 4,
                          }}
                        >
                          Main Dish
                        </Col>
                        <Col
                          className="fs-6 text-center mt-2 mb-2 border border-grey"
                          style={{
                            fontWeight: "700",
                            marginRight: 12,
                            marginLeft: 12,
                            borderRadius: 6,
                            paddingTop: 4,
                            paddingBottom: 4,
                          }}
                        >
                          Vegetables
                        </Col>
                        <Col
                          className="fs-6 text-center mt-2 mb-2 border border-grey"
                          style={{
                            fontWeight: "700",
                            marginRight: 12,
                            marginLeft: 12,
                            borderRadius: 6,
                            paddingTop: 4,
                            paddingBottom: 4,
                          }}
                        >
                          Side Dish A
                        </Col>
                        <Col
                          className="fs-6 text-center mt-2 mb-2 border border-grey"
                          style={{
                            fontWeight: "700",
                            marginRight: 12,
                            marginLeft: 12,
                            borderRadius: 6,
                            paddingTop: 4,
                            paddingBottom: 4,
                          }}
                        >
                          Side Dish B
                        </Col>
                      </Row>

                      <Row>{renderFoodImages(date, data)}</Row>

                      <Row>{renderFoodItems(date, data)}</Row>
                    </Card>
                  ))}
                </>
              ) : (
                <p style={{ textAlign: "center", fontSize: 12 }}>
                  Incorrect date range. Please check your date.
                </p>
              )
            ) : (
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ minHeight: "100px" }}
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
                <div style={{ marginTop: "10px" }}>Loading...</div>
              </div>
            )
          ) : (
            <p></p>
          )
        ) : (
          <p style={{ textAlign: "center", fontSize: 12 }}>
            Incorrect date range. Please check your date.
          </p>
        )}
      </Container>

      <Footer />
    </div>
  );
}

export default Meal;
